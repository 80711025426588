import React from 'react';
import bgImageBlue from "../Assets/DreamJobLogos/bgImage.png";
// import companyLogo from "../Assets/DreamJobLogos/companyLogo.png";
import buildingIcon from "../Assets/DreamJobLogos/buildingIcon.png";
import locationIcon from "../Assets/DreamJobLogos/locationIcon.png";

import { useLocation } from 'react-router-dom';

const AllCards2 = () => {
    const location = useLocation();
    const { cards2 } = location.state; // Access cards2 from the state
  
    return (
      <div className="w-[85vw] mx-auto my-10 p-8 bg-[#ffffff]">
        <h1 className="text-[24px] font-semibold text-[#000000] mb-6 text-center font-poppins">
          All Skill Enhancement Opportunities
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {cards2.map((card, index) => (
            <div
              key={index}
              className="relative bg-cover bg-center rounded-lg overflow-hidden p-6"
              style={{ backgroundImage: `url(${bgImageBlue})` }}
            >
              <div className="absolute flex justify-center items-center top-4 left-4 w-16 h-16 bg-white rounded-full overflow-hidden">
                <img src={card.profileImage} alt="Profile" className="w-[50%] h-[50%] object-cover" />
              </div>
              <div className="absolute top-7 right-4 text-[#00000080] text-[16px] font-poppins">
                <button className="text-[#7E75FF] rounded-xl text-[16px] px-2 py-1 font-medium font-poppins bg-[#E1DFFC]">
                  Course
                </button>
              </div>
              <div className="mt-20 mb-[15px] leading-[40px] text-[#000000] font-semibold text-[16px] text-start font-poppins">
                {card.companyName}
              </div>
              <div className="flex items-center mt-[2px] mb-[10px]">
                <img src={buildingIcon} alt="Logo 1" className="w-5 h-5 mr-2" />
                <span className="text-[#000000] text-[16px] font-normal font-poppins">{card.logo1Text}</span>
              </div>
              <div className="flex items-center mt-[2px] mb-[10px]">
                <img src={locationIcon} alt="Logo 2" className="w-7 h-7 mr-1 ml-[-3px]" />
                <span className="text-[#00000099] text-[16px] font-normal font-poppins">{card.logo2Text}</span>
              </div>
              <div className="mb-[10px] text-[#000000] font-normal text-[16px] text-start mt-[2px] font-poppins">
                {card.training}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default AllCards2;