import React from "react";
import Carrier1 from "../Assets/images/Carrier1.png";
import Carrier2 from "../Assets/images/Carrier2.png";
import Carrier3 from "../Assets/images/Carrier3.png";
import Carrier4 from "../Assets/images/Carrier4.png";

const CarrierPotentials = () => {
  const cards = [
    {
      title: "Enhance Your Skills",
      description:
        "Access a range of courses and resources to upskill and stay ahead in your field.",
      buttonText: "EXPLORE MORE",
      bgColor: "#FE5252E5",
      textColor: "text-red-600",
      img: Carrier1,
    },
    {
      title: "Discover Your Next Job",
      description:
        "Browse and apply for the latest job openings tailored to your career goals.",
      buttonText: "EXPLORE MORE",
      bgColor: "#5D77B4",
      textColor: "text-blue-600",
      img: Carrier2,
    },
    {
      title: "Our Job Fairs",
      description:
        "Connect with industry leaders and explore opportunities at upcoming career fairs and events.",
      buttonText: "CONNECT",
      bgColor: "#FFA57A",
      textColor: "text-orange-600",
      img: Carrier4,
    },
    {
      title: "Craft Your Perfect Resume",
      description:
        "Create a standout resume with our easy-to-use builder and get noticed by employers.",
      buttonText: "CREATE",
      bgColor: "#84D696",
      textColor: "text-green-600",
      img: Carrier3,
    },
  ];

  return (
    <div className="w-[95vw] mx-auto p-8 font-poppins">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 lg-gap-4">
        {cards.map((card, index) => (
          <div
            key={index}
            className={`relative h-[410px] lg:h-[450px] xl:h-[390px] rounded-[25px] transition-all duration-300 ease-in-out border-[12px] border-[#F8F9FA] 
       ${index === 0 ? "hover:border-[#ECD8D7]" : ""}
       ${index === 1 ? "hover:border-[#D7DAE2]" : ""} 
       ${index === 2 ? "hover:border-[#ECE0DB]" : ""}
       ${index === 3 ? "hover:border-[#ECE0DB]" : ""}
       box-border group`}
            style={{ backgroundColor: card.bgColor }}
          >
            {/* Image Section with Hover Effect */}
            <div className="flex justify-center items-center h-[155px] bg-[#FEEFDC] rounded-t-[10px] overflow-hidden">
              <img
                src={card.img}
                alt={`${card.title} illustration`}
                className={`${
                  index === 3 ? "w-[40%] pt-3" : "w-[78%]"
                } h-[90%] transition-transform duration-300 ease-out group-hover:scale-125`}
              />
            </div>

            {/* Text Content */}
            <div className="p-[15px]">
              <h3 className="text-[20px] text-white text-start font-bold mb-3 mt-2">
                {card.title}
              </h3>
              <p className="text-white text-[16px]  text-start font-normal mb-3 leading-[19px]">
                {card.description}
              </p>
            </div>

            {/* Button Section */}
            <div className="absolute bottom-4 left-4  w-[60%%] xl:w-[55%] lg:w-[75%]">
              {/* <button
                className="w-full py-4 px-2 rounded-[12px] text-white text-[14px] bg-[#F3F3F3] bg-opacity-30 hover:bg-opacity-50 transition"
              >
                {card.buttonText}
              </button> */}
            </div>
          </div>
        ))}
      </div>
      {/* <div className="mt-1 mx-4 md:mx-6 lg:mx-9 p-6 flex flex-col md:flex-row md:items-stretch gap-6 lg:gap-8">
        <div className="p-4 pb-0 bg-[#84D696] flex flex-col items-start gap-4 md:gap-4 relative w-full md:w-[45%] md:h-[432px] rounded-[20px] flex-grow overflow-hidden">
          <h4
            style={{ color: "#FFFFFF" }}
            className="p-4 pb-0 text-[32px] md:text-[32px] font-bold leading-[32px] md:leading-[40px] text-center md:text-left"
          >
            Craft Your Perfect Resume
          </h4>
          <p
            style={{ color: "#FFFFFF" }}
            className="pl-4 pr-4 text-[18px] font-normal leading-[24px] md:leading-[32px] text-start md:text-left"
          >
            Create a standout resume with our easy-to-use builder and get
            noticed by employers.
          </p>

          <div className="md:mt-[-120px] relative w-full h-full flex items-end justify-end">
            <img
              src={Carrier3}
              alt=""
              className=" lg:mb-[-17px] lg:mr-[-98px] md:mb-[-17px] md:mr-[-104px] sm:mb-[-10px] md:w-[320px] w-[250px] h-[120px] max-w-[353px] max-h-[200px] lg:w-[353px] lg:h-[180px] object-contain"
            />
          </div>
        </div>

        <div className="p-4 bg-[#FFA57A] flex flex-col items-start gap-4 md:gap-4 relative w-full md:w-[55%] md:h-[432px] rounded-[20px] flex-grow overflow-hidden">
          <h4
            style={{ color: "#FFFFFF" }}
            className="p-4 pb-0  text-[32px] md:text-[32px] font-bold leading-[32px] md:leading-[40px] text-center md:text-left"
          >
            Join Career Events
          </h4>
          <p
            style={{ color: "#FFFFFF" }}
            className="pl-4 pr-4 text-[18px] md:text-[18px] font-normal leading-[24px] md:leading-[32px] text-start md:text-left"
          >
            Connect with industry leaders and explore opportunities at upcoming
            career fairs and events.
          </p>

          <div className="md:mt-[-102px] relative w-full h-full flex items-end justify-end">
            <img
              src={Carrier4}
              alt=""
              className="lg:mb-[-45px] lg:mr-[-10px] md:mb-[-30px] md:mr-[-10px] sm:mb-[-10px] w-[280px] h-[180px] max-w-[389.86px] max-h-[266px] lg:w-[375.86px] lg:h-[256px] object-contain"
            />
          </div>
        </div>
      </div> */}

      {/* <div className="mt-9 mx-4 md:mx-6 lg:mx-6 p-6 pt-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-6 lg:gap-7 xl:gap-8">
        <div className="p-4 bg-[#FE5252] flex flex-col items-start gap-4 relative w-full h-auto md:h-[432px] lg:h-[432px] rounded-[20px] overflow-hidden pb-20">
          {" "}
          
          <h4 className="p-4 pb-0 text-[32px] below-sm:text-[18px] font-bold leading-[32px] md:leading-[40px] lg:leading-[40px] text-center md:text-left  lg:text-left text-white">
            Enhance Your Skills
          </h4>
          <p className="pl-4 pr-4 text-[18px] below-sm:text-[14px] font-normal leading-[24px] md:leading-[32px] lg:leading-[32px] text-start md:text-left lg:text-left text-white">
            Access a range of courses and resources to upskill and stay ahead in
            your field.
          </p>
          <div className="absolute bottom-0 right-0">
            <img
              src={Carrier1}
              alt=""
              className="w-[150px] h-[100px] md:w-[200px] md:h-[140px] lg:w-[210px] lg:h-[150px]"
            />
          </div>
        </div>

        <div className="p-4 bg-[#4B6AB0] flex flex-col items-start gap-4 relative w-full h-auto md:h-[432px] lg:h-[432px] rounded-[20px] overflow-hidden pb-20">
          <h4 className="p-4 pb-0 text-[32px] below-sm:text-[18px] font-bold leading-[32px] md:leading-[40px] lg:leading-[40px] text-center md:text-left lg:text-left text-white">
            Discover Your Next Job
          </h4>
          <p className="pl-4 pr-4 text-[18px] below-sm:text-[14px] font-normal leading-[24px] md:leading-[32px] lg:leading-[32px] text-start md:text-left md:text-left text-white">
            Browse and apply for the latest job openings tailored to your career
            goals.
          </p>
          <div className="absolute bottom-0 right-0">
            <img
              src={Carrier2}
              alt=""
              className="w-[150px] h-[100px] md:w-[200px] md:h-[140px] lg:w-[210px] lg:h-[150px] "
            />
          </div>
        </div>

        <div className="p-4 bg-[#84D696] flex flex-col items-start gap-4 relative w-full h-auto md:h-[432px] lg:h-[432px] rounded-[20px] overflow-hidden pb-20">
          <h4 className="p-4 pb-0 text-[32px] below-sm:text-[18px] font-bold leading-[32px] md:leading-[40px] lg:leading-[40px] xl:leading-[36px] text-center md:text-left lg:text-left text-white">
            Craft Your Perfect Resume
          </h4>
          <p className="pl-4 pr-4 text-[18px] below-sm:text-[14px] font-normal leading-[24px] md:leading-[32px] lg:leading-[32px] xl:leading-[30px] text-start md:text-left lg:text-left text-white">
            Create a standout resume with our easy-to-use builder and get
            noticed by employers.
          </p>
          <div className="absolute bottom-0 right-0">
            <img
              src={Carrier3}
              alt=""
              className="w-[150px] h-[100px] md:w-[200px] md:h-[140px] lg:w-[210px] lg:h-[150px]"
            />
          </div>
        </div>

        <div className="p-4 bg-[#FFA57A] flex flex-col items-start gap-4 relative w-full h-auto md:h-[432px] lg:h-[432px] rounded-[20px] overflow-hidden pb-20">
          <h4 className="p-4 pb-0 text-[32px] below-sm:text-[18px] font-bold leading-[32px] md:leading-[40px] lg:leading-[40px] text-center md:text-left lg:text-left text-white">
            Join Career Events
          </h4>
          <p className="pl-4 pr-4 text-[18px] below-sm:text-[14px] font-normal leading-[24px] md:leading-[32px] lg:leading-[32px] text-start md:text-left lg:text-left text-white">
            Connect with industry leaders and explore opportunities at upcoming
            career fairs and events.
          </p>
          <div className="absolute bottom-0 right-0">
            <img
              src={Carrier4}
              alt=""
              className="w-[150px] h-[100px] md:w-[200px] md:h-[140px] lg:w-[210px] lg:h-[150px]"
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default CarrierPotentials;
