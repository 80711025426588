import React, { useState } from "react";
import { Link } from 'react-router-dom';
import NA from "../Assets/images/N&A1.png";
import NA2 from "../Assets/images/N&A2.png";
import NewsBG from "../Assets/images/NewsBG.png";
import NewsBG1 from "../Assets/images/NewsBG1.png";
import Slider from "react-slick";
import tsb from "../Assets/banners/tsb.jpg";
// import ib from "../Assets/banners/Interview Blog.jpg";
// import jfb from "../Assets/banners/job_fair_blog.jpg";


const CustomPrevArrow = ({ onClick }) => (
  <button
    className="absolute top-[101%] left-[35%] transform -translate-y-1/2 z-10"
    onClick={onClick}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 15 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.67568 13L15 3.03333L12.1622 0L0 13L12.1622 26L15 22.9667L5.67568 13Z"
        fill="#192C56"
      />
    </svg>
  </button>
);

const CustomNextArrow = ({ onClick }) => (
  <button
    className="absolute top-[101%] right-[35%] transform -translate-y-1/2 z-10"
    onClick={onClick}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 15 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.32432 13.5L0 3.53333L2.83784 0.5L15 13.5L2.83784 26.5L0 23.4667L9.32432 13.5Z"
        fill="#192C56"
      />
    </svg>
  </button>
);

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
  ],
};
const newsdata = [
  {
    img: NA,
    blog_link:
      "https://www.lokmatnews.in/technology/govts-whatsapp-chatbot-will-help-labourers-look-for-work-in-home-states-b540/",
    detail1: `अब नौकरी खोजने में सरकारी व्हाट्सएप चैटबॉट करेगा मदद, अपने ही राज्य के जॉब की मिलेगी जानकारी`,
    detail2: `Technology Information, Forecasting and Assessment Council (TIFAC), think-tank under the Department of Science and Technology has launched job portal Saksham that will conduct skills mapping exercises of workers as per requirements of MSMEs.`,
  },
  {
    img: NA2,
    blog_link:
      "https://www.livemint.com/industry/manufacturing/govt-launches-job-portal-for-placing-workers-in-msmes-5-things-to-know-11613019802423.html",
    detail1: `TIFAC unveils two new initiatives of Technology —SAKSHAM and a Seaweed Mission`,
    detail2: `Two new initiatives of Technology Information, Forecasting and Assessment Council (TIFAC) —SAKSHAM (Shramik Shakti Manch)- a dynamic job portal for mapping the skills of Shramiks vis-à-vis requirements of MSMEs to directly connect Shramiks with MSMEs and facilitate placement.`,
  },
  {
    img: tsb,
    blog_link:
      "https://www.indianarrative.com/economy-news/govt-aims-to-bring-recruitment-agencies-across-india-under-one-umbrella-148474.html",
    detail1: `Govt. forms Saksham—an umbrella body to fight unemployment`,
    detail2: `The government has launched ‘Saksham,’ an umbrella initiative aimed at combating unemployment across the country. This platform will work to boost employment opportunities by directly connecting workers with MSMEs, facilitating a smoother placement process.`,
  },
  // {
  //   img: NA,
  //   blog_link:
  //     "https://www.indianarrative.com/economy-news/govt-aims-to-bring-recruitment-agencies-across-india-under-one-umbrella-148474.html",
  //   detail1: `अब नौकरी खोजने में सरकारी व्हाट्सएप चैटबॉट करेगा मदद, अपने ही राज्य के जॉब की मिलेगी जानकारी`,
  //   detail2: `Technology Information, Forecasting and Assessment Council (TIFAC), think-tank under the Department of Science and Technology has launched job portal Saksham that will conduct skills mapping exercises of workers as per requirements of MSMEs.`,
  // },
  // {
  //   img: NA2,
  //   blog_link:
  //     "https://affairscloud.com/tifac-launches-2-initiatives-saksham-job-portal-sea-weed-mission/",
  //   detail1: `TIFAC unveils two new initiatives of Technology —SAKSHAM and a Seaweed Mission`,
  //   detail2: `Two new initiatives of Technology Information, Forecasting and Assessment Council (TIFAC) —SAKSHAM (Shramik Shakti Manch)- a dynamic job portal for mapping the skills of Shramiks vis-à-vis requirements of MSMEs to directly connect Shramiks with MSMEs and facilitate placement.`,
  // },
  // {
  //   img: tsb,
  //   blog_link:
  //     "https://www.peoplematters.in/news/strategic-hr/government-plans-to-bring-recruitment-agencies-across-india-under-one-umbrella-38549",
  //   detail1: `Top Skills Employers Are Looking for in 2024: How to Stay Competitive in the Job Market`,
  //   detail2: `The job market is evolving rapidly, and for freshers or individuals from low-income backgrounds, staying competitive can feel like a challenge. Whether you're entering the workforce for the first time or looking to switch careers, mastering the right skills can make all the difference. In 2024, employers across industries are focusing on specific skills that can help candidates stand out and succeed. Here's a detailed guide on what skills employers are seeking this year and how you can acquire them to boost your job prospects.`,
  // },
  // {
  //   img: ib,
  //   blog_link: "https://pib.gov.in/PressReleseDetailm.aspx?PRID=1697085",
  //   detail1: `How to Ace Your Next Job Interview: Expert Tips and Common Mistakes to Avoid`,
  //   detail2: `Preparing for a job interview can be a nerve-wracking experience, especially for freshers or candidates from low-income backgrounds. The competition is fierce, and making a strong impression during the interview is crucial to securing the job you want. However, many job seekers make avoidable mistakes that can hurt their chances. In this guide, we'll walk you through expert tips on how to ace your next job interview and common pitfalls to avoid. By following these strategies, you can enter your interview with confidence and poise.`,
  // },
  // {
  //   img: jfb,
  //   blog_link:
  //     "https://www.peoplematters.in/news/strategic-hr/government-plans-to-bring-recruitment-agencies-across-india-under-one-umbrella-38549",
  //   detail1: `The Importance of Job Fairs: How Attending Can Boost Your Career Prospects`,
  //   detail2: `For freshers and job seekers from low-income communities, landing a job can feel like an uphill battle. The competition is fierce, and navigating the job market can be overwhelming. One of the best ways to increase your chances of success is by attending job fairs, where you can meet multiple employers in one place and explore various career opportunities.
  //   Job fairs not only give you access to job openings but also provide invaluable networking opportunities, exposure to real-world hiring processes, and a chance to sharpen your job search skills. Let’s dive into why job fairs are so important for your career and how they can give you an edge in the competitive job market.`,
  // },
];

const News = () => {
  const [more, setMore] = useState(false);
  const [id, setId] = useState(null);
  return (
      <>
       {/* <Link
                  to="/BlogsArticles"
                  target="_blank"
                  rel="noopener noreferrer"
                > */}
    <div className="w-[88vw] mx-auto rounded-[12px] p-8 pb-[30px] bg-[#ffffff]">
 
      <div className="justify-between pl-[9px] flex">
        <p className="flex justify-start text-[20px] font-semibold font-poppins">
          Blogs and Articles
        </p>
         {/* <p>
         <Link
              to="/BlogsArticles"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#26397B] text-[16px] underline font-medium font-poppins"
            >
              View All
            </Link>
            </p>  */}
      </div>
      <Slider {...settings}>
        {newsdata.map((d, index) => {
          

          return (
            <div
              key={index}
              // style={{
              //   backgroundImage: url(backgroundImage),
              //   backgroundSize: "cover",  
              //   backgroundPosition: "center",
              //   backgroundRepeat: "no-repeat",
              // }}
              // bg-[#FFFBE6]
              className="bg-custom flex flex-col w-full md:w-[calc(50%-1rem)] rounded-[20px] p-[24px] h-[420px] sm:h-[590px] md:h-[500px] lg:h-[500px]  below-sm:h-auto mb-[23px] pb-[30px]"
            >
              <div className="w-full rounded-[20px] overflow-hidden">
                <a
                  href="https://www.example.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={d.img}
                    className="w-full h-[180px] rounded-[20px] object-cover"
                    alt=""
                  />
                </a>
              </div>
              <p className="text-[#192C56] text-[20px] font-normal font-poppins text-center md:text-left py-4 md:text-base">
                {d.detail1}
              </p>
              <p className="text-[#192C56] text-[20px]  font-normal font-poppins text-center md:text-left md:text-base">
                {d.detail2.length > 120 ? (
                  <div>
                    {more && id === index ? (
                      <span>{d.detail2}</span>
                    ) : (
                      <span>{d.detail2.substring(0, 120) + ".... "}</span>
                    )}
                    <a
                      href={d.blog_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 underline"
                    >
                      Click here
                    </a>
                  </div>
                ) : (
                  <span>{d.detail2}</span>
                )}
              </p>
            </div>
          );
        })}
      </Slider>
    </div>
    
    </>
  );
};

export default News;
