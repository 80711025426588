import React from 'react';
import UM from "../Assets/images/UM-Img.png";

const JobProvider = () => {
  return (
    <div className='items-center justify-center flex'> 
        <img src={UM}className='h-[90vh] w-[80vw]' />
     </div>
  )
}

export default JobProvider