import React from "react";
import Logo from "../Assets/images/Image1.png";
import E_logo from "../Assets/images/Email_L.png";
import Fb_logo from "../Assets/images/Fb_logo.png";
import X_logo from "../Assets/images/X_logo.png";
import YT_logo from "../Assets/images/YT_logo.png";
import IG_logo from "../Assets/images/IG_logo.png";
import LI_logo from "../Assets/images/LI_logo.png";
import FooterBG from "../Assets/images/FooterBG.png";
import FooterLine from "../Assets/images/footerL.png";
import gplayBtn from "../Assets/images/googlePlayBtn.png";
import { Link } from "react-router-dom";
import SakshamLogo from "../Assets/images/Saksham_Logo.svg";

function Footer() {
  // const navigate = useNavigate();
  return (
    <div className=" bg-[#E7E8EA4A] w-full pt-[25px]">
      <footer
      className="w-full shadow px-[10px] py-[35px] pb-4 relative  "
      style={{
        backgroundImage: `url(${FooterBG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        <div className="w-full max-w-screen-xl mx-auto">
        <div className="w-full flex justify-center items-center max-w-screen-xl mx-auto ">
      <img src={SakshamLogo} alt="Logo" className="w-[58px] h-[49px] below-sm:w-[88px] below-sm:h-[71px]" />
    </div>
    <div className="mt-5 ">
            <p className="text-center text-[14px]  leading-[17px] font-normal font-poppins text-[#FFFFFF] px-[35px]">
              An apex mission to end unemployment in India. An initiative by
              TIFAC, an autonomous organisation under the Government of India
              and implemented by Sapio Analytics.
            </p>
          </div>
          <div className="justify-center flex  mt-[22px] pb-[35px]">
          <a
                    href="https://play.google.com/store/apps/details?id=com.saksham.saksham&pli=1"
                    target="_blank"
                  >
            <img src={gplayBtn} className="w-[113px] h-[35px] " /></a>
          </div>
          
        </div>

        <div className="flex flex-col w-full max-w-screen-xl gap-6 px-4 pb-4 lg:gap-7 md:gap-7  ]">
          <div className="flex items-start justify-between ">
          
            <div className=" text-start w-full max-w-xs md:pl-[2px] sm:pl-[150px] xl:pl-[70px] below-sm:pl-[10px]">
              <h2 className="mb-8 text-[18px] font-semibold text-[#FFFFFF]">
                Quick Links
              </h2>
              <ul className="text-gray-500 font-poppins dark:text-gray-400 font-medium cursor-pointer">
                <li className="mb-3 text-white font-normal">
                  <a href="" className="hover:underline">
                    Home
                  </a>
                </li>
                <li className="mb-3 text-white font-normal">
                  <a href="" className="hover:underline">
                    Job Provider
                  </a>
                </li>
                <li className="mb-3 text-white font-normal">
                  <a href="" className="hover:underline">
                    Job Seeker
                  </a>
                </li>
                <li className="mb-3 text-white font-normal">
                  <a href="" className="hover:underline">
                    Events
                  </a>
                </li>
                <li className="mb-3 text-white font-normal">
                  <a href="" className="hover:underline">
                    About Us
                  </a>
                </li>
              </ul>
            </div>

            <div className="flex items-center">
              <img src={FooterLine} className=" h-[193px] mr"></img>
              {/* <svg
                width="1"
                height="197"
                viewBox="0 0 1 197"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line x1="0.5" y1="0" x2="0.500007" y2="157" stroke="white" />
              </svg> */}
            </div>

            <div className="w-full max-w-xs text-start pl-[42px] ">
              <div lcassName="flex justify-start ">
              <h2 className="mb-8  font-poppins font-bold text-[#FFFFFF] text-[20px]">
                Resources
              </h2>
              <ul className="text-gray-500 font-poppins  dark:text-gray-400 font-normal text-[16px]" >
                <li className="mb-3 text-white font-normal">
                  <a href="" className="hover:underline">
                  Blogs and Article
                  </a>
                </li>
                <li className="mb-3 text-white font-poppins  font-normal text-[16px]">
                  <a href="" className="hover:underline">
                  Whats new
                  </a>
                </li>
                <li className="mb-3 text-white font-poppins text-[16px] font-normal">
                  {/* <Link
                    // to="/Privacy-Policy-Saksham.pdf"
                    target="_blank"
                    to="/privacypolicy"
                    // download
                  > */}
                    <p className="hover:underline">Privacy Policy</p>
                  {/* </Link> */}
                </li>
                <li className="mb-3 text-white font-poppins  font-normal text-[16px]">
                  <a href="" className="hover:underline">
                  Success story
                  </a>
                </li>
                <li className="mb-3 text-white font-poppins  font-normal text-[16px]">
                  <a href="" className="hover:underline">
                  Field Force
                  </a>
                </li>
                

              </ul>
              </div>
            </div>
          </div>

          
        </div>

        <div className="w-full flex flex-col md:items-start items-center  md:pl-20  ">
          <h5 className="text-[18px] text-[#FFFFFF] font-semibold mb-3">
            Subscribe Now
          </h5>
          {/* <p className="text-[#FFFFFF] text-[16px] leading-[17px] font-normal mb-5">
            Don’t miss our future updates! Get Subscribed Today!
          </p> */}
          <form className="flex flex-row justify-center lg:flex-row md:items-start xl:justify-start sm:items-center below-sm:items-center gap-2 w-full">
            <input
              type="email"
              placeholder="Enter your email here"
              className="md:w-[200px] xl:w-[280px] w-[280px] h-[36px] sm:w-auto px-4 py-2 text-black rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <button
              type="submit"
              className="bg-white rounded-[5px] w-[36px] h-[36px] flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <img src={E_logo} alt="Submit" className="w-[24px] h-[24px]" />
            </button>
          </form>
          <p className="text-white font-poppins font-semibold text-[18px] below-sm:text-[14px] mt-[45px] below-sm:py-4 underline">
            Connect with us
          </p>
          <ul className="flex gap-3">
            <li>
              <a
                href="https://www.linkedin.com/company/sakshamtifac/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Fb_logo} alt="LinkedIn" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/sakshamtifac/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={X_logo} alt="Instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/people/Saksham/100068920491625/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={LI_logo} alt="Facebook" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <svg width="99%" height="3" viewBox="0 0 1320 3" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="-0.000757575" y1="2.5" x2="1320" y2="0.500001" stroke="white" stroke-opacity="0.29"/>
</svg>

      {/* Paragraph Below the Grid Boxes */}
      <p className="font-bold font-poppins  flex text-center justify-center text-[9px] text-[#FFFFFF] mt-5">
            Disclaimer: Shramik and industry information on this portal will
            only be used to increase employment opportunities and for Govt.
            policy formation regarding skill assessment, gaps, needs, and
            training through data analytics. Only workers/industries who give
            consent will have information shared.
          </p>
      <p className="pt-2 below-sm:pt-5 font-poppins flex text-center text-white justify-center font-bold text-[9px]">
        ©2024. Saksham by TIFAC. All Rights Reserved.
      </p>
    </footer>
    </div>
  );
}

export default Footer;
