import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import JFS5 from "../Assets/images/JFS5.png";
import JFS6 from "../Assets/images/JFS6.png";
import JFS7 from "../Assets/images/JFS7.png";
import JFS8 from "../Assets/images/JFS8.png";
import JFS11 from "../Assets/images/JFS11.png";
import JFS12 from "../Assets/images/JFS12.png";

const slides = [JFS5, JFS6, JFS7, JFS8, JFS11, JFS12];

const sliderSettings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2200,
  autoplaySpeed: 2000,
  arrows: false,
  pauseOnHover: true,
};

const caption =
  "Saksham regularly organises job fairs, career fairs, and career counselling sessions to connect job seekers with employers. These events provide direct interaction, job opportunities, and personalized career guidance to help individuals improve their skills and prepare for interviews. Stay informed about upcoming events and take the next step in your career with Saksham!";

const JobFairSec = () => {
  return (
    <>
      <div className="font-poppins job-events-slider-container mx-auto w-[88vw] flex xl:flex-row lg:flex-row flex-col space-y-4  flex my-10 lg:py-[70px] lg:px-[70px] xl:py-[70px] xl:px-[70px] py-[40px] px-[40px] bg-white rounded-md shadow-lg ">
        <div className="flex flex-col xl:mr-[-270px] lg:mr-[-170px]">
          <div className="flex flex-col w-[100%] xl:w-[70%] lg:w-[70%] ">
            <h3 className="font-poppins text-2xl text-start font-bold text-orange-500 mb-[10px]">
              Job Fairs <span className="text-[#233862]">& Events</span>{" "}
            </h3>
            <p className="font-poppins text-sm text-start text-gray-500 mb-6">
              {caption}
            </p>
          </div>
          {/* <button className="mt-1 mb-6 lg:mt-6 xl:mt-6 w-[140px] px-4 py-3 bg-orange-500  text-white text-[14px] font-poppins font-medium rounded-[24px] hover:bg-orange-600 transition">
        Explore More
      </button> */}
        </div>

        <div className="w-[75%] xl:w-[30%] lg:w-[30%] sm:w-[55%]">
          <Slider {...sliderSettings}>
            {slides.map((src, index) => (
              <div key={index} className="flex justify-center">
                <img
                  src={src}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-auto object-cover rounded-lg transition-transform duration-300 hover:scale-105"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default JobFairSec;
