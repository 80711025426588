import React from 'react'
import personIcon from "../Assets/BandA/PersonIcon.png";
import RecImg from "../Assets/BandA/RecImage.png";
import Nav from "./Nav.js";
import Navbar from "../Pages/Navbar.js";
import Footer from "../Pages/Footer.js";


const blogs = [
  {
    id: 0,
    image: personIcon,
    icon: RecImg,
    author: 'Rakesh',
    date: '16 JAN 2024',
    title: 'Improve your design skills: Develop a “EYE” for design',
    description:
      'As users become more demanding and tech-savvy, designers are challenged to create products that are not only visually appealing but also intuitive and functional. This blog explores the fundamental principles of UI/UX design, from understanding user needs to implementing best practices for seamless digital experiences.',
  },
  {
    id: 1,
    image: personIcon,
    icon: RecImg,
    author: 'Rakesh',
    date: '16 JAN 2024',
    title: 'Improve your design skills: Develop a “EYE” for design',
    description:
      'As users become more demanding and tech-savvy, designers are challenged to create products that are not only visually appealing but also intuitive and functional. This blog explores the fundamental principles of UI/UX design, from understanding user needs to implementing best practices for seamless digital experiences.',
  },
  {
    id: 2,
    image: personIcon,
    icon: RecImg,
    author: 'Rakesh',
    date: '16 JAN 2024',
    title: 'Improve your design skills: Develop a “EYE” for design',
    description:
      'As users become more demanding and tech-savvy, designers are challenged to create products that are not only visually appealing but also intuitive and functional. This blog explores the fundamental principles of UI/UX design, from understanding user needs to implementing best practices for seamless digital experiences.',
  },
  {
    id: 3,
    image: personIcon,
    icon: RecImg,
    author: 'Rakesh',
    date: '16 JAN 2024',
    title: 'Improve your design skills: Develop a “EYE” for design',
    description:
      'As users become more demanding and tech-savvy, designers are challenged to create products that are not only visually appealing but also intuitive and functional. This blog explores the fundamental principles of UI/UX design, from understanding user needs to implementing best practices for seamless digital experiences.',
  },
  {
    id: 4,
    image: personIcon,
    icon: RecImg,
    author: 'Rakesh',
    date: '16 JAN 2024',
    title: 'Improve your design skills: Develop a “EYE” for design',
    description:
      'As users become more demanding and tech-savvy, designers are challenged to create products that are not only visually appealing but also intuitive and functional. This blog explores the fundamental principles of UI/UX design, from understanding user needs to implementing best practices for seamless digital experiences.',
  },
  {
    id: 5,
    image: personIcon,
    icon: RecImg,
    author: 'Rakesh',
    date: '16 JAN 2024',
    title: 'Improve your design skills: Develop a “EYE” for design',
    description:
      'As users become more demanding and tech-savvy, designers are challenged to create products that are not only visually appealing but also intuitive and functional. This blog explores the fundamental principles of UI/UX design, from understanding user needs to implementing best practices for seamless digital experiences.',
  },
];

const BlogsArticles = () => {
  return (
    <>
    <Nav />
    <Navbar />
    <div className='px-[60px] '>
      <p className='font-medium text-[33px] text-start pt-[30px] font-poppins'> Recent blog post's </p>
    <div className="">
    <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {blogs.map((blog) => (
        <div key={blog.id} className="bg-white  rounded-lg overflow-hidden">
          <img src={blog.icon} alt={blog.title} className="w-full h-48 object-cover rounded-lg" />
          <div className="py-4 px-[4px]">
            <div className="flex items-start space-x-2 justify-start">
              <img src={blog.image} alt="Author Icon" className="w-8 h-8" />
              <div className="flex justify-center items-center space-x-2 pt-[8px] ">
              <span className="text-[#434343] text-[13px] font-semibold font-poppins">{blog.author}</span>
              <span className="text-[#434343] text-[23px] font-semibold font-poppins mt-[-8px]">.</span>
              <span className="text-[#434343] text-[13px] font-semibold font-poppins ">{blog.date}</span>
               </div>
            </div>
           
            <h3 className="text-[15px] leading-[20px] text-start font-medium font-poppins mt-2">{blog.title}</h3>
            <p className="text-[#000000BF] leading-[17px] font-normal text-[9px] text-start font-poppins mt-2">{blog.description}</p>
            <div className="flex justify-start font-poppins font-medium ">
            <button className="mt-4 bg-[#192C56] text-white text-[9px] py-[10px] px-[15px] rounded-[4px] ">
              Know More
            </button>
              </div>
          </div>
        </div>
      ))}
    </div>
    </div>
    </div>
    <div className='px-[60px] '>
      <p className='font-medium text-[33px] text-start pt-[30px] font-poppins'> Featured blog post's  </p>
    <div className="">
    <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {blogs.map((blog) => (
        <div key={blog.id} className="bg-white  rounded-lg overflow-hidden">
          <img src={blog.icon} alt={blog.title} className="w-full h-48 object-cover rounded-lg" />
          <div className="py-4 px-[4px]">
            <div className="flex items-start space-x-2 justify-start">
              <img src={blog.image} alt="Author Icon" className="w-8 h-8" />
              <div className="flex justify-center items-center space-x-2 pt-[8px] ">
              <span className="text-[#434343] text-[13px] font-semibold font-poppins">{blog.author}</span>
              <span className="text-[#434343] text-[23px] font-semibold font-poppins mt-[-8px]">.</span>
              <span className="text-[#434343] text-[13px] font-semibold font-poppins ">{blog.date}</span>
               </div>
            </div>
           
            <h3 className="text-[15px] leading-[20px] text-start font-medium font-poppins mt-2">{blog.title}</h3>
            <p className="text-[#000000BF] leading-[17px] font-normal text-[9px] text-start font-poppins mt-2">{blog.description}</p>
            <div className="flex justify-start font-poppins font-medium ">
            <button className="mt-4 bg-[#192C56] text-white text-[9px] py-2 px-4 rounded">
              Know More
            </button>
              </div>
          </div>
        </div>
      ))}
    </div>
    </div>
    </div>
    <Footer />
    </>
  );
}

export default BlogsArticles