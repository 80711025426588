// import React, { useState } from "react";
// import success from "../Assets/Real/success1.png";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import inverted from "../Assets/images/inverted.png";
// import p2 from "../Assets/Real/p2.jpeg";
// import p4 from "../Assets/Real/p4.jpeg";
// import p6 from "../Assets/Real/p6.jpeg";
// import p1 from "../Assets/p1.jpeg";
// import p3 from "../Assets/p3.jpeg";
// import p5 from "../Assets/p5.jpeg";
// import C1 from "../Assets/images/Arceus.png";
// import C2 from "../Assets/images/EbixCash.png";
// import C3 from "../Assets/New Logos/debt.png";

// const sliderSettings = {
//   dots: true,
//   infinite: true,
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   autoplay: true,
//   speed: 2200,
//   autoplaySpeed: 2000,
//   cssEase: "linear",
//   pauseOnHover: true,
//   pauseOnFocus: false,
//   prevArrow: (
//     <div className="slick-prev">
//       <svg
//         width="15"
//         height="27"
//         viewBox="0 0 15 27"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           d="M5.67568 13L15 3.03333L12.1622 0L0 13L12.1622 26L15 22.9667L5.67568 13Z"
//           fill="#192C56"
//         />
//       </svg>
//     </div>
//   ),
//   nextArrow: (
//     <div className="slick-next">
//       <svg
//         width="15"
//         height="27"
//         viewBox="0 0 15 27"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           d="M9.32432 13.5L0 3.53333L2.83784 0.5L15 13.5L2.83784 26.5L0 23.4667L9.32432 13.5Z"
//           fill="#192C56"
//         />
//       </svg>
//     </div>
//   ),
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 2,
//       },
//     },
//     {
//       breakpoint: 768,
//       settings: {
//         slidesToShow: 2,
//       },
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1,
//       },
//     },
//   ],
// };

// const Stories = () => {
//   const [selectedCategory, setSelectedCategory] = useState("jobSeeker");

//   const slides = [
//     // Job Seeker testimonials
//     {
//       image: success,
//       text: "I previously worked in the e-commerce industry, but due to unforeseen circumstances, I lost my job and felt incredibly discouraged. That’s when I discovered Saksham. Through their support, I identified the skills most relevant to my profile and successfully transitioned into a role in the retail industry. I’m truly grateful for the opportunities Saksham has provided.",
//       author: "Satyaprakash Chaubey",
//       bgColor: "#E6FAD9",
//       category: "jobSeeker",
//     },
//     {
//       image: p1,
//       text: "I attended a job fair through Saksham, and to my surprise, I received an offer letter on the spot for the role of Field Collections Officer at SecureDebt. The whole process was smooth, and I’m thankful for the opportunity Saksham provided to help me find the right job so quickly.",
//       author: "Rahul Langadey",
//       bgColor: "#FCF5D0",
//       category: "jobSeeker",
//     },
//     {
//       image: p3,
//       text: "Through one of Saksham’s partner training programs, I gained the skills I needed to be able to find a job. At a job fair hosted by Saksham, I found the right job for me. Thanks to Saksham’s services, I secured a role that matches my skills and career goals.",
//       author: "Siddesh Namapalli",
//       bgColor: "#D9D7FB",
//       category: "jobSeeker",
//     },
//     // Job Provider testimonials
//     {
//       image: C2,
//       text: "At Ebix Cash Global Pvt Ltd, we have numerous openings, and they continue to grow. Through Saksham’s associated job fairs, we were able to source a significant number of qualified candidates. While we still have many positions to fill, we are confident that Saksham’s future job fairs will continue to play a crucial role in our hiring process.",
//       author: "Ebix Cash Global",
//       bgColor: "#FCF5D0",
//       category: "jobProvider",
//     },
//     {
//       image: C1,
//       text: "As a provider of telecommunications workforce solutions for government initiatives, we constantly need to repurpose our workforce while seeking qualified candidates. Saksham’s offerings have been essential in helping us bridge this gap, ensuring we have access to the right talent when we need it.",
//       author: "Arceus",
//       bgColor: "#FCF5D0",
//       category: "jobProvider",
//     },
//     {
//       image: C3,
//       text: "Saksham has been a key partner in helping us meet our continuous hiring needs for telecommunication and field collection roles. Through their offerings and job fairs, we’ve successfully sourced qualified candidates and look forward to continuing our partnership with them.",
//       author: "SecureDebt",
//       bgColor: "#D9D7FB",
//       category: "jobProvider",
//     },
//   ];

//   const filteredSlides = slides.filter(slide => slide.category === selectedCategory);

//   return (
//     <div className="w-full mt-20 px-10 md:px-14">
//       <p className="font-bold text-[40px] below-sm:text-[20px] mt-5 mb-7">
//         Testimonials
//       </p>

//       <div className="mb-7">
//         <button
//           className={`px-4 py-2 mr-4 rounded font-semibold text-[18px] ${selectedCategory === "jobSeeker" ? "text-blue-600 underline" : "text-gray-500"}`}
//           onClick={() => setSelectedCategory("jobSeeker")}
//         >
//           Job Seeker
//         </button>
//         <button
//           className={`px-4 py-2 rounded font-semibold text-[18px] ${selectedCategory === "jobProvider" ? "text-blue-600 underline" : "text-gray-500"}`}
//           onClick={() => setSelectedCategory("jobProvider")}
//         >
//           Job Provider
//         </button>
//       </div>


//       <Slider {...sliderSettings}>
//         {filteredSlides.map((slide, index) => (
//           <div
//             key={index}
//             className={`relative flex flex-col md:flex-row ${(index + 1) % 3 === 2
//                 ? "bg-[#FCF5D0]"
//                 : (index + 1) % 3 === 0
//                   ? "bg-[#D9D7FB]"
//                   : "bg-[#E6FAD9]"
//               }  rounded-[20px] p-4 md:p-6 min-h-[200px] h-[650px] below-sm:h-[560px]`}
//           >
//             <div className="absolute top-2 right-2 p-1 block sm:block">
//               <img
//                 src={inverted}
//                 alt="Inverted"
//                 className="h-[45px] w-[75px] md:h-[55px] md:w-[90px] sm:h-[60px] sm:w-[85px]"
//               />
//             </div>

//             <div className="flex-shrink-0 h-[150px] w-[50%] md:h-[150px] lg:h-[150px] md:w-[50%] sm:w-[50%] below-sm:w-[50%]  mb-4 md:mb-0">
//               <img
//                 src={slide.image}
//                 alt=""
//                 className="w-full h-full object-contain rounded-20px"
//               />
//             </div>

//             <div className="mt-9 flex flex-col justify-start w-full text-left">
//               <p className="text-[14px] md:text-[16px] lg:text-[16px] text-[#333] mb-4 leading-[20px] md:leading-[26px] lg:leading-[28px] text-justify">
//                 {slide.text}
//               </p>
//             </div>
//             <p className="text-[18px] mt-3 md:text-[24px] lg:text-[24px] text-[#333] font-bold text-justify">
//               {slide.author}
//             </p>
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// };

// export default Stories;


import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useState } from 'react';
import Slider from 'react-slick';

import success from "../Assets/Real/success1.png";
import p1 from "../Assets/p1.jpeg";
import p3 from "../Assets/p3.jpeg";

import C1 from "../Assets/images/Arceus.png";
import C2 from "../Assets/images/EbixCash.png";
import C3 from "../Assets/New Logos/debt.png";

import BgImage1 from "../Assets/testtimonials/image1.png";
import BgImage2 from "../Assets/testtimonials/image2.png";
import BgImage3 from "../Assets/testtimonials/image3.png";



const CustomPrevArrow = ({ onClick }) => (
  <button
    className="absolute top-[102%] left-[35%] transform -translate-y-1/2 z-10"
    onClick={onClick}
  >
      
      <svg
        width="20"
        height="20"
        viewBox="0 0 15 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.67568 13L15 3.03333L12.1622 0L0 13L12.1622 26L15 22.9667L5.67568 13Z"
          fill="#192C56"
        />
      </svg>
  
  
  </button>
);

const CustomNextArrow = ({ onClick }) => (
  <button
    className="absolute top-[102%] right-[35%] transform -translate-y-1/2 z-10"
    onClick={onClick}
  >
    
  
      <svg
        width="20"
        height="20"
        viewBox="0 0 15 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.32432 13.5L0 3.53333L2.83784 0.5L15 13.5L2.83784 26.5L0 23.4667L9.32432 13.5Z"
          fill="#192C56"
        />
      </svg>
  </button>
);

const testimonials = {
  jobSeeker: [
    {
      name: 'Satyaprakash Chaubey',
      image: success,
      description:
        'I used to work in the e-commerce industry. Due to some factors, I ended up losing my job. I was really discouraged, but then I came across Saksham. I understood the skills that are relevant to me, and my profriend found a position for me in the retail industry. Thank you, Saksham.',
    },
    {
      name: 'Rahul Langadey',
      image: p1,
      description:
        'I attended a job fair through Saksham, and to my surprise, I received an offer letter on the spot for the role of Field Collections Officer at SecureDebt. The whole process was smooth, and I’m thankful for the opportunity Saksham provided to help me find the right job so quickly.',
    },
    {
      name: 'Siddesh Namapalli',
      image: p3,
      description:
        'Through one of Saksham’s partner training programs, I gained the skills I needed to be able to find a job. At a job fair hosted by Saksham, I found the right job for me. Thanks to Saksham’s services, I secured a role that matches my skills and career goals.',
    },
    
  ],
  jobProvider: [
    {
      name: 'Ebix Cash Global',
      image: C2, 
      description:
        'At Ebix Cash Global Pvt Ltd, we have numerous openings, and they continue to grow. Through Saksham’s associated job fairs, we were able to source a significant number of qualified candidates. While we still have many positions to fill, we are confident that Saksham’s future job fairs will continue to play a crucial role in our hiring process.',
    },
    {
      name: 'Arceus',
      image: C1, 
      description:
        'As a provider of telecommunications workforce solutions for government initiatives, we constantly need to repurpose our workforce while seeking qualified candidates. Saksham’s offerings have been essential in helping us bridge this gap, ensuring we have access to the right talent when we need it.',
    },
    {
      name: 'SecureDebt',
      image: C3, 
      description:
        'Saksham has been a key partner in helping us meet our continuous hiring needs for telecommunication and field collection roles. Through their offerings and job fairs, we’ve successfully sourced qualified candidates and look forward to continuing our partnership with them.',
    },
    
  ],
};

const Stories = () => {
  const [activeTab, setActiveTab] = useState('jobSeeker'); // Toggle state

  // Slick slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 768, // Tablet
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false, // Hide arrows on smaller screens
        },
      },
    ],
  };

  const currentTestimonials = testimonials[activeTab];

  return (
    <div className="w-[88vw] mx-auto my-10 p-8 bg-[#ffffff] rounded-[12px] shadow-lg">
      {/* Tabs */}
      <div className='justify-start pl-[5px]'>
        <p className="flex justify-center text-[20px] font-semibold font-poppins">Testimonials</p>

      </div>

      {/* Description */}
      <p className=" pl-[5px] mb-[23px] text-center text-[#00000099] text-[16px] font-normal font-poppins">
        See how our platform helped candidates secure their dream roles and advance their careers.
      </p>
      
      <div className="flex justify-center gap-6 mb-6 pl-[5px]">
        <button
          className={`text-[16px] font-normal font-poppins bg-[#F8F9FA] border-[1px] border-[#CBC2C2]  px-[10px] py-[6px] rounded-[7px] ${
            activeTab === 'jobSeeker' ? 'text-[#26397B] underline' : 'text-[#000000]'
          }`}
          onClick={() => setActiveTab('jobSeeker')}
        >
          Job Seeker
        </button>
        <button
          className={`text-[16px] font-poppins font-normal bg-[#F8F9FA] border-[1px] border-[#CBC2C2] px-[10px] py-[6px] rounded-[7px] ${
            activeTab === 'jobProvider' ? 'text-[#26397B] underline' : 'text-[#000000]'
          }`}
          onClick={() => setActiveTab('jobProvider')}
        >
          Job Provider
        </button>
      </div>
      {/* Carousel */}
      <div>
      <Slider {...settings}>
        {currentTestimonials.map((testimonial, index) => 
        (
        
          <div
          key={index}
          className="flex flex-col md:flex-row items-center bg-[#F8F9FA] p-6 rounded-lg shadow-md gap-4 mb-[30px]"

        >
         

          
          {/* Left Section: Image and Name */}
          <div className='flex flex-col lg:flex-row w-[100%] gap-[10px] lg:gap-[0px]'>

          <div className='lg:w-[35%] xl:w-[30%] w-[100%] lg:items-start text-center'>
          <div>
          <div
          
            className={`relative flex flex-row items-start justify-center below-sm:w-[120px] below-sm:h-[110px] w-[173px] h-[186px] md:h-[186px] p-[10px] rounded-[30px]  ${(index + 1) % 3 === 2 ? "bg-custom1"
              : (index + 1) % 3 === 0
                ? "bg-custom2"
                : "bg-custom3"
} `}
              // index === 0
              //   ? "bg-[#E6FAD9]"
              //   : index === 1
              //   ? "bg-[#0E2A59]"
              //   : index === 2
              //   ? "bg-[#FD8D67]"
              //   : "bg-[#0E2A59]"

            // }`}

           
          >
            {/* Name */}
            <div className='pt-[25px] mr-[15px]'>
            <h3 className=" font-poppins flex items-start text-[14px] font-medium text-[#000000] mb-2 z-10">
              {testimonial.name}
            </h3>
            </div>
            {/* Image */}
            <div>
            <img
              src={testimonial.image}
              alt={testimonial.name}
              className="absolute left-[75%] top-[18%] w-[128px] h-[128px] below-sm:w-[60px] below-sm:h-[70px] rounded-[5px] object-contain ml-[5px]"
            />
            </div>
          </div>
          </div>
          </div>
      
          {/* Right Section: Description */}
          <div className="flex text-justify items-center lg:w-[65%] xl:w-[80%] w-[100%] pl-[10px]" >
            <p className="text-[#000000] font-poppins text-[16px]">{testimonial.description}</p>
          </div>      
          </div>
          </div>
        ))}
      </Slider>
      </div>
    </div>
  );
};

export default Stories;
