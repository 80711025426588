import React from "react";
// import Job1 from "../Assets/images/Job1.png";
// import Carrier2 from "../Assets/images/Carrier2.png";
import Job1 from "../Assets/images/JobSec1.png";
import Carrier2 from "../Assets/images/JobSec3.png";
// import dots from "../Assets/images/dotted.png";

const Carrier = () => {
  return (
    <div className="my-11 px-4 p-3  flex flex-col md:flex-row mx-auto w-[90vw] rounded-[12px] md:justify-between md:gap-6 lg:gap-8 items-stretch">
      <div className="p-4 flex flex-col items-start md:items-start gap-2 md:gap-2 relative w-full md:w-[calc(50%-1rem)] mt-6 md:mt-0 rounded-[12px] bg-[#FFFFFF] flex-grow shadow-lg border-t-[22px] transition-all duration-300 ease-in-out border-[#FFFFFFAB] hover:border-[#444B8C] hover:bg-[#E0E2E9]">
        <h4
          style={{ color: "#192C56" }}
          className=" px-[15px] pb-0 text-start text-[24px] font-semibold font-poppins md:text-left"
        >
          Assess Your Job Readiness
        </h4>
        <p
          style={{ color: "#192C56" }}
          className=" px-[15px] text-[22px] font-normal font-poppins sm:text-justify leading-[31px] text-left md:text-left"
        >
          Evaluate your skills, experience, and qualifications with our
          comprehensive Job Readiness Score. Get personalised insights and tips
          to improve your employability and stand out to potential employers.
        </p>
        <div className="pl-[15px]">
          {/* <button className=" px-4 py-4 text-[#444B8C] font-poppins font-medium rounded-[14px] border-[3px] border-[#444B8C] hover:bg-[#444B8C] hover:text-[#ffffff] transition">
Access More
</button> */}
        </div>

        <div className="relative w-full h-full flex-grow">
          <div className="w-full flex md:justify-between lg:justify-between  xl:justify-between justify-between  items-end h-full">
            {/* <img src={dots} className="w-[35%] h-[10%]"/> */}
            <div className="hidden sm:flex">
              <svg
                width="170"
                height="124"
                viewBox="0 0 170 124"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.15">
                  <ellipse
                    cx="12.363"
                    cy="116.976"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="12.363"
                    cy="94.9856"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="12.363"
                    cy="72.9953"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="12.363"
                    cy="51.0049"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.88"
                  />
                  <g filter="url(#filter0_d_714_899)">
                    <ellipse
                      cx="12.363"
                      cy="29.0148"
                      rx="8.363"
                      ry="7.02463"
                      fill="#233862"
                      fill-opacity="0.78"
                      shape-rendering="crispEdges"
                    />
                  </g>
                  <ellipse
                    cx="12.363"
                    cy="7.02463"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.75"
                  />
                  <ellipse
                    cx="42.7458"
                    cy="116.976"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="42.7458"
                    cy="94.9856"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="42.7458"
                    cy="72.9953"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="42.7458"
                    cy="51.0051"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.88"
                  />
                  <g filter="url(#filter1_d_714_899)">
                    <ellipse
                      cx="42.7458"
                      cy="29.0149"
                      rx="8.363"
                      ry="7.02463"
                      fill="#233862"
                      fill-opacity="0.78"
                      shape-rendering="crispEdges"
                    />
                  </g>
                  <ellipse
                    cx="42.7458"
                    cy="7.02463"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.75"
                  />
                  <ellipse
                    cx="73.1208"
                    cy="116.976"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="73.1208"
                    cy="94.9856"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="73.1208"
                    cy="72.9953"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="73.1208"
                    cy="51.0051"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.88"
                  />
                  <g filter="url(#filter2_d_714_899)">
                    <ellipse
                      cx="73.1208"
                      cy="29.0149"
                      rx="8.363"
                      ry="7.02463"
                      fill="#233862"
                      fill-opacity="0.78"
                      shape-rendering="crispEdges"
                    />
                  </g>
                  <ellipse
                    cx="73.1208"
                    cy="7.02463"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.75"
                  />
                  <ellipse
                    cx="103.504"
                    cy="116.976"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="103.504"
                    cy="94.9856"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="103.504"
                    cy="72.9953"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="103.504"
                    cy="51.0051"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.88"
                  />
                  <g filter="url(#filter3_d_714_899)">
                    <ellipse
                      cx="103.504"
                      cy="29.0149"
                      rx="8.363"
                      ry="7.02463"
                      fill="#233862"
                      fill-opacity="0.78"
                      shape-rendering="crispEdges"
                    />
                  </g>
                  <ellipse
                    cx="103.504"
                    cy="7.02463"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.75"
                  />
                  <ellipse
                    cx="133.879"
                    cy="116.976"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="133.879"
                    cy="94.9856"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="133.879"
                    cy="72.9953"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="133.879"
                    cy="51.0051"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.88"
                  />
                  <g filter="url(#filter4_d_714_899)">
                    <ellipse
                      cx="133.879"
                      cy="29.0149"
                      rx="8.363"
                      ry="7.02463"
                      fill="#233862"
                      fill-opacity="0.78"
                      shape-rendering="crispEdges"
                    />
                  </g>
                  <ellipse
                    cx="133.879"
                    cy="7.02463"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.75"
                  />
                  <ellipse
                    cx="164.261"
                    cy="116.976"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="164.261"
                    cy="94.9856"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="164.261"
                    cy="72.9953"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="164.261"
                    cy="51.0051"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.88"
                  />
                  <g filter="url(#filter5_d_714_899)">
                    <ellipse
                      cx="164.261"
                      cy="29.0149"
                      rx="8.363"
                      ry="7.02463"
                      fill="#233862"
                      fill-opacity="0.78"
                      shape-rendering="crispEdges"
                    />
                  </g>
                  <ellipse
                    cx="164.261"
                    cy="7.02463"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.75"
                  />
                  <ellipse
                    cx="194.636"
                    cy="116.976"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="194.636"
                    cy="94.9856"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="194.636"
                    cy="72.9953"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="194.636"
                    cy="51.0051"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.88"
                  />
                  <g filter="url(#filter6_d_714_899)">
                    <ellipse
                      cx="194.636"
                      cy="29.0149"
                      rx="8.363"
                      ry="7.02463"
                      fill="#233862"
                      fill-opacity="0.78"
                      shape-rendering="crispEdges"
                    />
                  </g>
                  <ellipse
                    cx="194.636"
                    cy="7.02463"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.75"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_714_899"
                    x="0"
                    y="21.9902"
                    width="24.7266"
                    height="22.0493"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_714_899"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_714_899"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter1_d_714_899"
                    x="30.3828"
                    y="21.9902"
                    width="24.7266"
                    height="22.0493"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_714_899"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_714_899"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter2_d_714_899"
                    x="60.7578"
                    y="21.9902"
                    width="24.7266"
                    height="22.0493"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_714_899"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_714_899"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter3_d_714_899"
                    x="91.1406"
                    y="21.9902"
                    width="24.7266"
                    height="22.0493"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_714_899"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_714_899"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter4_d_714_899"
                    x="121.516"
                    y="21.9902"
                    width="24.7266"
                    height="22.0493"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_714_899"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_714_899"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter5_d_714_899"
                    x="151.898"
                    y="21.9902"
                    width="24.7266"
                    height="22.0493"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_714_899"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_714_899"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter6_d_714_899"
                    x="182.273"
                    y="21.9902"
                    width="24.7266"
                    height="22.0493"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_714_899"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_714_899"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
            <div>
              <img
                src={Job1}
                alt="Ace Your Interviews"
                className="w-[342.36px] h-[169.49px] md:w-[352.36px] md:h-[179.49px] below-sm:w-[240px] object-contain"
                style={{ position: "relative", bottom: "0%", right: "-5%" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="p-4 flex flex-col items-start md:items-start gap-2 md:gap-2 relative w-full md:w-[calc(50%-1rem)] mt-6 md:mt-0 rounded-[12px] bg-[#FFFFFF]  flex-grow shadow-lg border-t-[22px] transition-all duration-300 ease-in-out border-[#FFFFFFAB] hover:border-[#444B8C]  hover:bg-[#E0E2E9]">
        <h4
          style={{ color: "#192C56" }}
          className="px-[15px] pb-0 text-start text-[24px] font-semibold font-poppins md:text-left"
        >
          Ace Your Interviews with Expert Preparation
        </h4>
        <p
          style={{ color: "#192C56" }}
          className="px-[15px] text-[22px] font-normal leading-[31px] font-poppins text-left md:text-left"
        >
          Prepare for success with our tailored mock interviews and interview
          coaching. Practice real-world scenarios, receive feedback, and refine
          your responses to boost your confidence and performance in any job
          interview.
          <span style={{ lineHeight: "normal", display: "inline" }}> </span>
        </p>
        <div className="pl-[15px]">
          {/* <button className="px-4 py-4 text-[#444B8C] font-poppins font-medium rounded-[14px] border-[3px] border-[#444B8C] hover:bg-[#444B8C] hover:text-[#ffffff] transition">
Know More
</button> */}
        </div>

        <div className="relative w-full h-full flex-grow">
          <div className="w-full flex md:justify-between lg:justify-between  xl:justify-between justify-between   items-end h-full">
            {/* <img src={dots} className="w-[35%] h-[10%]"/> */}
            <div className="hidden sm:flex">
              <svg
                width="160"
                height="124"
                viewBox="0 0 170 124"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.15">
                  <ellipse
                    cx="12.363"
                    cy="116.976"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="12.363"
                    cy="94.9856"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="12.363"
                    cy="72.9953"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="12.363"
                    cy="51.0049"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.88"
                  />
                  <g filter="url(#filter0_d_714_899)">
                    <ellipse
                      cx="12.363"
                      cy="29.0148"
                      rx="8.363"
                      ry="7.02463"
                      fill="#233862"
                      fill-opacity="0.78"
                      shape-rendering="crispEdges"
                    />
                  </g>
                  <ellipse
                    cx="12.363"
                    cy="7.02463"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.75"
                  />
                  <ellipse
                    cx="42.7458"
                    cy="116.976"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="42.7458"
                    cy="94.9856"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="42.7458"
                    cy="72.9953"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="42.7458"
                    cy="51.0051"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.88"
                  />
                  <g filter="url(#filter1_d_714_899)">
                    <ellipse
                      cx="42.7458"
                      cy="29.0149"
                      rx="8.363"
                      ry="7.02463"
                      fill="#233862"
                      fill-opacity="0.78"
                      shape-rendering="crispEdges"
                    />
                  </g>
                  <ellipse
                    cx="42.7458"
                    cy="7.02463"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.75"
                  />
                  <ellipse
                    cx="73.1208"
                    cy="116.976"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="73.1208"
                    cy="94.9856"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="73.1208"
                    cy="72.9953"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="73.1208"
                    cy="51.0051"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.88"
                  />
                  <g filter="url(#filter2_d_714_899)">
                    <ellipse
                      cx="73.1208"
                      cy="29.0149"
                      rx="8.363"
                      ry="7.02463"
                      fill="#233862"
                      fill-opacity="0.78"
                      shape-rendering="crispEdges"
                    />
                  </g>
                  <ellipse
                    cx="73.1208"
                    cy="7.02463"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.75"
                  />
                  <ellipse
                    cx="103.504"
                    cy="116.976"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="103.504"
                    cy="94.9856"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="103.504"
                    cy="72.9953"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="103.504"
                    cy="51.0051"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.88"
                  />
                  <g filter="url(#filter3_d_714_899)">
                    <ellipse
                      cx="103.504"
                      cy="29.0149"
                      rx="8.363"
                      ry="7.02463"
                      fill="#233862"
                      fill-opacity="0.78"
                      shape-rendering="crispEdges"
                    />
                  </g>
                  <ellipse
                    cx="103.504"
                    cy="7.02463"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.75"
                  />
                  <ellipse
                    cx="133.879"
                    cy="116.976"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="133.879"
                    cy="94.9856"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="133.879"
                    cy="72.9953"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="133.879"
                    cy="51.0051"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.88"
                  />
                  <g filter="url(#filter4_d_714_899)">
                    <ellipse
                      cx="133.879"
                      cy="29.0149"
                      rx="8.363"
                      ry="7.02463"
                      fill="#233862"
                      fill-opacity="0.78"
                      shape-rendering="crispEdges"
                    />
                  </g>
                  <ellipse
                    cx="133.879"
                    cy="7.02463"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.75"
                  />
                  <ellipse
                    cx="164.261"
                    cy="116.976"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="164.261"
                    cy="94.9856"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="164.261"
                    cy="72.9953"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="164.261"
                    cy="51.0051"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.88"
                  />
                  <g filter="url(#filter5_d_714_899)">
                    <ellipse
                      cx="164.261"
                      cy="29.0149"
                      rx="8.363"
                      ry="7.02463"
                      fill="#233862"
                      fill-opacity="0.78"
                      shape-rendering="crispEdges"
                    />
                  </g>
                  <ellipse
                    cx="164.261"
                    cy="7.02463"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.75"
                  />
                  <ellipse
                    cx="194.636"
                    cy="116.976"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="194.636"
                    cy="94.9856"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="194.636"
                    cy="72.9953"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                  />
                  <ellipse
                    cx="194.636"
                    cy="51.0051"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.88"
                  />
                  <g filter="url(#filter6_d_714_899)">
                    <ellipse
                      cx="194.636"
                      cy="29.0149"
                      rx="8.363"
                      ry="7.02463"
                      fill="#233862"
                      fill-opacity="0.78"
                      shape-rendering="crispEdges"
                    />
                  </g>
                  <ellipse
                    cx="194.636"
                    cy="7.02463"
                    rx="8.363"
                    ry="7.02463"
                    fill="#233862"
                    fill-opacity="0.75"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_714_899"
                    x="0"
                    y="21.9902"
                    width="24.7266"
                    height="22.0493"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_714_899"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_714_899"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter1_d_714_899"
                    x="30.3828"
                    y="21.9902"
                    width="24.7266"
                    height="22.0493"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_714_899"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_714_899"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter2_d_714_899"
                    x="60.7578"
                    y="21.9902"
                    width="24.7266"
                    height="22.0493"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_714_899"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_714_899"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter3_d_714_899"
                    x="91.1406"
                    y="21.9902"
                    width="24.7266"
                    height="22.0493"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_714_899"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_714_899"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter4_d_714_899"
                    x="121.516"
                    y="21.9902"
                    width="24.7266"
                    height="22.0493"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_714_899"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_714_899"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter5_d_714_899"
                    x="151.898"
                    y="21.9902"
                    width="24.7266"
                    height="22.0493"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_714_899"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_714_899"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter6_d_714_899"
                    x="182.273"
                    y="21.9902"
                    width="24.7266"
                    height="22.0493"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_714_899"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_714_899"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>

            <div className="items-end flex ">
              <img
                src={Carrier2}
                alt="Ace Your Interviews"
                className="w-[315.36px] h-[160.49px]  below-sm:w-[240px] object-contain"
                style={{ position: "relative", bottom: "0%", right: "0%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carrier;
