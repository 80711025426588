import React, { useState, useEffect } from 'react';
import axios from "axios";
import Select from "react-select";
import "antd/dist/reset.css"; 
import { Pagination } from "antd";
import Nav from "./Nav.js";
import Navbar from "../Pages/Navbar.js";
import Footer from "../Pages/Footer.js";

// import companyLogo from "../Assets/DreamJobLogos/companyLogo.png";
import buildingIcon from "../Assets/DreamJobLogos/buildingIcon.png";
import locationIcon from "../Assets/DreamJobLogos/locationIcon.png";
// import orgLogo from "../Assets/DreamJobLogos/companyLogo.png";
import Apply from "../Assets/DreamJobLogos/ApplyIcon.png";
import job_img from "../Assets/Real/jobs.png";
import job from "../Assets/icons/job.png";
import { useLocation } from 'react-router-dom';


const AllCards = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to top whenever the route changes
    window.scrollTo(0, 0);

  }, [location]); // Effect runs whenever the location changes (i.e., route changes)

  

  const optionsTitle = [
    { label: "Commercial Executive", value: "Commercial Executive" },
    { label: "Debt Collection Agent", value: "Debt Collection Agent" },
    { label: "Sales Executive", value: "Sales Executive" },
    { label: "Field Sales Executive", value: "Field Sales Executive" },
    { label: "Telesales", value: "Telesales" },
    { label: "Telecaller", value: "Telecaller" },
    { label: "Human Resources", value: "Human Resources" },
    { label: "Credit Manager", value: "Credit Manager" },
    { label: "Customer Sales Executive", value: "Customer Sales Executive" },
    { label: "Assistant Manager", value: "Assistant Manager" },
    { label: "MIS Executive", value: "MIS Executive" },
    { label: "International Caller", value: "International Caller" },
    { label: "Accountant", value: "Accountant" },
    { label: "Tele Collection Officer", value: "Tele Collection Officer" },
    { label: "Pharmacist", value: "Pharmacist" },
    { label: "Company Secretary", value: "Company Secretary" },
    { label: "Business Development Executive", value: "Business Development Executive" },
  ];
  
  const [activeIndex, setActiveIndex] = useState(0);
  const [state, setState] = useState([]);
  const [stateValue, setStateValue] = useState("");
  const [district, setDistrict] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(90);
  const [total, setTotal] = useState(0);
  // const [screen, setScreen] = useState(1);
  const locationObj = useLocation();

  const stripHtmlTags = (html) => {
    if (!html) return ''; // Check if html is null, undefined, or an empty string
    return html.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
  };

  const [jobData, setJobData] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [search, setSearch] = useState({
    job_title: "",
    state: "",
    district: "",
  });

  const stylesDD = {
    container: (provided) => ({
      ...provided,
      // width: "200px", //window.innerWidth <= 600 ? "50px" :
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      minHeight: "40px",
      border: state.isFocused ? "none" : "none", 
      boxShadow: state.isFocused ? "none" : "none",
      fontSize: "14px",
    }),
    indicatorSeparator: () => ({
      display: "none", // Hides the vertical separator
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted red`,
      height: "100%",
      fontSize: "14px",
    }),
  };




  const handleSearch = async () => {
    setActiveIndex(0);
    setSearchPerformed(true);
    // Check if any of the search fields are populated
    if (search.job_title !== "" || search.state !== "" || search.district !== "") {
      try {
        // Fetch filtered jobs from the backend
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/form/saksham-jobs`,
          {
            params: {
              job_title: search.job_title,
              state: search.state,
              district: search.district.value, // Adjusted for district object
            },
          }
        );
  
        setJobs(response.data.data); // Set the filtered jobs
        setTotal(response.data.data.length); // Update total job count
        setCurrentPage(1); // Reset to the first page
      } catch (err) {
        console.error("Error fetching filtered jobs:", err);
      }
    } else {
      // If no search fields are populated, fetch all jobs
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/form/saksham-jobs`
        );
  
        setJobs(response.data.data); // Set all jobs
        setTotal(response.data.data.length); // Update total job count
        setCurrentPage(1); // Reset to the first page
      } catch (err) {
        console.error("Error fetching all jobs:", err);
      }
    }
  };
  

  // Calculate the data to be displayed on the current page
  const currentData = jobs.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );




  
  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        setState(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDistricts = () => {
    const params = {
      state: stateValue,
    };
    const paramString = new URLSearchParams(params).toString();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
        // {
        //   state: selectedState,
        // }
      )
      .then((res) => {
        setDistrict(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchStateList();
  }, []);
  useEffect(() => {
    fetchDistricts();
  }, [stateValue]);
  // Fetch jobs when component mounts
  const fetchJobs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/form/saksham-jobs`);
      console.log(response.data.data);
      setJobData(response.data.data);
    } catch (error) {
      console.error("Error fetching job data:", error);
    }
  };


  useEffect(() => {
    fetchJobs();
  }, []); 

  const activeData = currentData[activeIndex] || jobData[activeIndex];
  const [searchPerformed, setSearchPerformed] = useState(false);
  

  return (
    <div>
      <Nav />
      <Navbar /> 
      <div className="font-poppins flex flex-col min-h-screen bg-white p-4">
    
    <div className="flex justify-center">
    <div className="flex justify-center w-[55%] my-4">
          <div className="w-full lg:w-[100%] bg-transparent lg:bg-[#fff] lg:border-2 lg:border-[#000000B8] rounded-full below-lg:px-4">
            <div className="flex flex-col md:flex-row items-center gap-4 w-full px-2 md:px-6 rounded-full">
              {/* Search Icon */}
              <div className="w-[5%] hidden md:block">
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>

              {/* Search Input */}
              <div className="flex flex-col w-full md:w-[95%] md:flex-row items-center justify-between rounded-full">
                
                <div className="w-full md:w-[60%] mt-2 md:mt-0">
                  <Select
                    styles={{
                      ...stylesDD,
                      control: (base, state) => ({
                        ...base,
                        borderRadius: "9999px", // Fully rounded dropdown
                        border: state.isFocused ? "1px solid #ccc" : "none", // Border for small screens when focused
                        boxShadow: state.isFocused ? null : null,
                        "@media (max-width: 1024px)": {
                          // For large screens (≥1024px)
                          border: "2px solid black", // No border for large screens
                        },
                        "@media (min-width: 1024px)": {
                          // For large screens (≥1024px)
                          border: "none", // No border for large screens
                        },
                      }),
                    }}
                    isClearable
                    size="small"
                    options={optionsTitle}
                    placeholder="Search Job Title"
                    value={
                      search.job_title !== ""
                        ? { label: search.job_title, value: search.job_title }
                        : null
                    }
                    
                    onChange={(e) => {
                      e
                        ? setSearch({ ...search, job_title: e.value })
                        : setSearch({ ...search, job_title: "" });
                    }}
                  />
                </div>

                {/* Divider */}
                <div className="hidden md:block">|</div>

                {/* State Select */}
                <div className="w-full md:w-[60%] mt-2 md:mt-0">
                  <Select
                    styles={{
                      ...stylesDD,
                      control: (base, state) => ({
                        ...base,
                        borderRadius: "9999px", // Fully rounded dropdown
                        border: state.isFocused ? "1px solid #ccc" : "none", // Border for small screens when focused
                        boxShadow: state.isFocused ? null : null,
                        "@media (max-width: 1024px)": {
                          // For large screens (≥1024px)
                          border: "2px solid black", // No border for large screens
                        },
                        "@media (min-width: 1024px)": {
                          // For large screens (≥1024px)
                          border: "none", // No border for large screens
                        },
                      }),
                    }}
                    isClearable
                    size="small"
                    options={state}
                    placeholder="Select State"
                    value={
                      stateValue !== ""
                        ? { label: stateValue, value: stateValue }
                        : null
                    }
                    onChange={(e) => {
                      if (e) {
                        setStateValue(e.value);
                        setSearch({ ...search, state: e.value });
                      } else {
                        setStateValue("");
                        setSearch({ ...search, state: "", district: "" });
                      }
                    }}
                  />
                </div>

                {/* Divider */}
                <div className="hidden md:block">|</div>

                {/* District Select */}
                <div className="w-full md:w-[60%] mt-2 md:mt-0">
                  <Select
                    styles={{
                      ...stylesDD,
                      control: (base, state) => ({
                        ...base,
                        borderRadius: "9999px", // Fully rounded dropdown
                        border: state.isFocused ? "1px solid #ccc" : "none", // Border for small screens when focused
                        boxShadow: state.isFocused ? null : null,
                        "@media (max-width: 1024px)": {
                          // For large screens (≥1024px)
                          border: "2px solid black", // No border for large screens
                        },
                        "@media (min-width: 1024px)": {
                          // For large screens (≥1024px)
                          border: "none", // No border for large screens
                        },
                      }),
                    }}
                    isClearable
                    size="small"
                    options={district}
                    placeholder="Select District"
                    value={search.district}
                    onChange={(e) => {
                      if (e) {
                        setSearch({ ...search, district: e });
                      } else {
                        setSearch({ ...search, district: "" });
                      }
                    }}
                  />
                </div>
              </div>

              {/* Search Button */}
              <div className="w-full md:w-[12%] flex justify-center lg:justify-end mt-0 md:mt-0">
                <button
                  onClick={() => handleSearch()}
                  className="text-white rounded-full bg-[#26397B] hover:bg-[#26397B] focus:ring-1 focus:outline-none focus:ring-[#26397B] font-medium text-sm px-[40px] py-[10px] md:px-4 md:py-2 my-[6px] dark:bg-[#F26622] dark:hover:bg-[#F26622] dark:focus:ring-[#F26622]"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div>
    {searchPerformed && (
        <div>
          {/* If there are no jobs */}
          {total === 0 ? (
            <p className="text-center mb-2 font-semibold text-xl text-red-500">
              No jobs available for this profile.
            </p>
          ) : (
            <div className="flex justify-center gap-4 items-center mx-4 mb-4">
               <div
                className="cursor-pointer pt-0 mt-[-12px]"
                onClick={() => {
                  setCurrentPage(1);
                  setSearch({
                    job_title: "",
                    state: "",
                    district: "",
                  });
                }}
              >
                {/* <svg
                  width="15"
                  height="20"
                  viewBox="0 0 15 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.67568 13.5L15 3.53333L12.1622 0.5L0 13.5L12.1622 26.5L15 23.4667L5.67568 13.5Z"
                    fill="#192C56"
                  />
                </svg> */}
              </div>
              <p className="text-center mb-2 font-semibold text-xl">
              {total} Jobs Available
            </p>
            </div>
          )}
        </div>
      )}
    </div>

    <div className="flex flex-row ">
      <div className="md:w-1/3 w-full md:h-[900px] md:overflow-y-auto scrollbar-hide bg-white shadow-sm rounded-lg pb-4 pr-4 pl-4 pt-0 mr-4">
      <div className="space-y-4">
  {currentData.length > 0 ? (
  currentData.map((d, index) => (
    <div key={index} className="flex flex-col space-y-2">
      {/* Job Card */}
      <div
        onClick={() => setActiveIndex(index)}
        className={`relative p-4 rounded-lg shadow-sm border-b-2 border-b-[#0000004D] cursor-pointer transition duration-200 
        ${activeIndex === index ? 'bg-custom-skills' : 'bg-white'}`}
      >
        {/* Company Logo */}
        <div className="absolute flex justify-center items-center top-4 left-4 w-16 h-16 bg-white rounded-full border-2 border-[#00000033] overflow-hidden">
          <img
            src={d.org_logo ? d.org_logo : job_img}
            alt="Profile"
            className="w-[50%] h-[50%] object-contain"
          />
        </div>

        {/* Days ago text */}
        <div className="absolute top-7 right-4 text-[#00000080] text-[16px] font-poppins">
          {d.daysAgo ? d.daysAgo : "-"}
        </div>

        {/* Job Designation */}
        <div className="mt-20 mb-[15px] text-[#000000] font-semibold text-[16px] text-start font-poppins">
          {d.designation ? d.designation : "-"}
        </div>

        {/* Company Name */}
        <div className="flex items-center mt-1 mb-[15px]">
          <img src={buildingIcon} alt="Company Icon" className="w-5 h-5 mr-2" />
          <span className="text-[#000000] text-start text-[16px] font-normal font-poppins">
            {d.org_name ? d.org_name : "-"}
          </span>
        </div>

        {/* Location */}
        <div className="flex items-center mt-1 mb-[2px]">
          <img src={locationIcon} alt="Location Icon" className="w-7 h-7 mr-1 ml-[-6px]" />
          <span className="text-[#00000099] text-start text-[16px] font-normal font-poppins">
            {d.district || d.state ? `${d.district || ''}, ${d.state || ''}` : "-"}
          </span>
        </div>
      </div>

      {/* Active Job Details (only shown for activeIndex) */}
      {index === activeIndex && (
        <div className="w-[100%] bg-custom-skills shadow-lg rounded-lg p-6 md:hidden block">
          {/* Active Job Content */}
          <div className="flex justify-between items-center mb-4">
            <div className="flex flex-row justify-center items-center">
              <div className="flex justify-center items-center w-14 h-14 bg-white border-b-2 border-b-[#0000004D] rounded-full overflow-hidden">
                <img
                  src={d.org_logo || job_img}
                  alt="Profile"
                  className="w-[50%] h-[50%] justify-center object-contain"
                />
              </div>
              <h1 className="text-xl font-semibold ml-[5px]">
                {d.designation || "-"}
              </h1>
            </div>
            <a
              href={d.link}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <button className="flex align-center bg-[#26397B] text-[16px] text-[#ffffff] rounded-[5px] font-poppins p-[10px] font-medium">
                Apply
                <img src={Apply} className="h-[17px] w-[17px]" alt="Apply Icon" />
              </button>
            </a>
          </div>

          {/* Additional Details */}
          <div className="flex flex-col justify-start text-start gap-[3px] text-gray-700 space-y-[3px]">
            <div className=" flex flex-row">
              <p className="text-sm text-gray-500 mt-[10px]">Job Type : </p>
              <p className="text-base font-medium text-gray-800 pl-[5px] mt-[10px]">
                {d.job_type || "-"}
              </p>
            </div>
            <div className="flex flex-row mt-[8px]">
              <p className="text-sm text-gray-500">Role : </p>
              <p className="text-base font-medium text-gray-800 pl-[5px]">
                {d.job_title || "-"}
              </p>
            </div>
            <div className="flex flex-row">
              <p className="text-sm text-gray-500">Salary Range : </p>
              <p className="text-base font-medium text-gray-800 pl-[5px]">
                {d.salary || "-"}
              </p>
            </div>
            <div className="mb-[15px]">
              <p className="text-sm text-gray-500 mb-[5px]">Description : </p>
              <p className="text-base font-medium text-gray-800 pl-[5px]">
                  {stripHtmlTags(d.jobdesc) || "-"}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  ))
)
 : jobData.map((d, index) => (
  <div className="flex flex-col space-y-2" key={index}>
    {/* Job Card */}
    <div
      onClick={() => {
        setActiveIndex(index); 
      }}
      className={`relative p-4 rounded-lg shadow-sm border-b-2 border-b-[#0000004D] cursor-pointer transition duration-200 
      ${activeIndex === index ? "bg-custom-skills" : "bg-white"}`}
    >
      {/* Company Logo */}
      <div className="absolute flex justify-center items-center top-4 left-4 w-16 h-16 bg-white rounded-full border-2 border-[#00000033] overflow-hidden">
        <img
          src={d.org_logo ? d.org_logo : job_img}
          alt="Profile"
          className="w-[50%] h-[50%] object-contain"
        />
      </div>

      {/* Days ago text */}
      <div className="absolute top-7 right-4 text-[#00000080] text-[16px] font-poppins">
        {d.daysAgo ? d.daysAgo : "-"}
      </div>

      {/* Job Designation */}
      <div className="mt-20 mb-[15px] text-[#000000] font-semibold text-[16px] text-start font-poppins">
        {d.designation ? d.designation : "-"}
      </div>

      {/* Company Name */}
      <div className="flex items-center mt-1 mb-[15px]">
        <img src={buildingIcon} alt="Company Icon" className="w-5 h-5 mr-2" />
        <span className="text-[#000000] text-start text-[16px] font-normal font-poppins">
          {d.org_name ? d.org_name : "-"}
        </span>
      </div>

      {/* Location */}
      <div className="flex items-center mt-1 mb-[2px]">
        <img src={locationIcon} alt="Location Icon" className="w-7 h-7 mr-1 ml-[-6px]" />
        <span className="text-[#00000099] text-start text-[16px] font-normal font-poppins">
          {d.district || d.state
            ? `${d.district || ""}, ${d.state || ""}`
            : "-"}
        </span>
      </div>
    </div>

    {/* Job Details Block */}
    {activeIndex === index && (
      <div className="w-[100%] bg-custom-skills shadow-lg rounded-lg p-6 md:hidden block">
        <div className="flex justify-between items-center mb-4">
          <div className="flex flex-row justify-center items-center">
            <div className="flex justify-center items-center w-14 h-14 bg-white border-b-2 border-b-[#0000004D] rounded-full overflow-hidden">
              <img
                src={d.org_logo || job_img}
                alt="Profile"
                className="w-[50%] h-[50%] justify-center object-contain"
              />
            </div>
            <h1 className="text-xl font-semibold ml-[5px]">
              {d.designation || "-"}
            </h1>
          </div>
          <a
            href={d.link}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="flex align-center bg-[#26397B] text-[16px] text-[#ffffff] rounded-[5px] font-poppins p-[10px] font-medium">
              Apply
              <img
                src={Apply}
                className="h-[17px] w-[17px]"
                alt="Apply Icon"
              />
            </button>
          </a>
        </div>

        {/* Company Name */}
        <div className="flex flex-row">
          <img
            src={buildingIcon}
            alt="Company Icon"
            className="w-5 h-5 mr-3"
          />
          <p className="text-gray-500 mb-[12px] text-start">
            {d.org_name || "-"}
          </p>
        </div>

        {/* Location and Days Ago */}
        <div className="flex justify-between border-b-2 border-b-[#0000004D]">
          <div className="flex flex-row">
            <img
              src={locationIcon}
              alt="Location Icon"
              className="w-[25px] h-[25px] mr-2"
            />
            <p className="text-gray-500 mb-[12px] text-start">
              {d.district || "-"} | {d.state || "-"}
            </p>
          </div>
          <div>
            <p>{d.daysAgo || "-"}</p>
          </div>
        </div>
        <hr className="mb-4" />

        {/* Job Details */}
        <div className="flex flex-col justify-start text-start gap-[3px] text-gray-700 space-y-[3px]">
          <div className=" flex flex-row">
            <p className="text-sm text-gray-500 mt-[10px]">Job Type : </p>
            <p className="text-base font-medium text-gray-800 pl-[5px] mt-[10px]">
              {d.job_type || "-"}
            </p>
          </div>
          <div className="flex flex-row mt-[8px]">
            <p className="text-sm text-gray-500">Role : </p>
            <p className="text-base font-medium text-gray-800 pl-[5px]">
              {d.job_title || "-"}
            </p>
          </div>
          <div className="flex flex-row">
            <p className="text-sm text-gray-500">Salary Range : </p>
            <p className="text-base font-medium text-gray-800 pl-[5px]">
              {d.salary || "-"}
            </p>
          </div>
          <div className="mb-[15px]">
            <p className="text-sm text-gray-500 mb-[5px]">Description : </p>
            <p className="text-base font-medium text-gray-800 pl-[5px]">
            {stripHtmlTags(d.jobdesc) || "-"}
            </p>
          </div>
        </div>

        {/* Qualifications */}
        <h2 className="text-lg font-semibold mb-2 mt-7 text-start">
          Qualifications
        </h2>
        <p className="text-start font-medium text-gray-800 mb-[5px] pl-[5px]">
          {d.qualification || "-"}
        </p>
      </div>
    )}
  </div>
))}

</div>

      </div>

      {/* Job Detail Section */}


{activeData && (
  <div className="w-2/3 bg-custom-skills h-auto shadow-lg rounded-lg p-6 hidden md:block">
    <div className="flex justify-between items-center mb-4">
      <div className="flex flex-row justify-center items-center">
        <div className="flex justify-center items-center w-14 h-14 bg-white border-b-2 border-b-[#0000004D] rounded-full overflow-hidden">
          <img
            src={activeData.org_logo || job_img}
            alt="Profile"
            className="w-[50%] h-[50%] justify-center object-contain"
          />
        </div>
        <h1 className="text-xl font-semibold ml-[5px]">
          {activeData.designation || "-"}
        </h1>
      </div>
      <a
        href={activeData.link}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="flex align-center bg-[#26397B] text-[16px] text-[#ffffff] rounded-[5px] font-poppins p-[10px] font-medium">
          Apply
          <img src={Apply} className="h-[17px] w-[17px]" alt="Apply Icon" />
        </button>
      </a>
    </div>

    {/* Company Name */}
    <div className="flex flex-row">
      <img src={buildingIcon} alt="Company Icon" className="w-5 h-5 mr-3" />
      <p className="text-gray-500 mb-[12px] text-start">
        {activeData.org_name || "-"}
      </p>
    </div>

    {/* Location and Days Ago */}
    <div className="flex justify-between border-b-2 border-b-[#0000004D]">
      <div className="flex flex-row">
        <img src={locationIcon} alt="Location Icon" className="w-[25px] h-[25px] mr-2" />
        <p className="text-gray-500 mb-[12px] text-start">
          {(activeData.district || "-")} | {(activeData.state || "-")}
        </p>
      </div>
      <div>
        <p>{activeData.daysAgo || "-"}</p>
      </div>
    </div>
    <hr className="mb-4" />

    {/* Job Details */}
    <div className="flex flex-col justify-start text-start gap-[3px] text-gray-700 space-y-[3px]">
      <div className=" flex flex-row">
        <p className="text-sm text-gray-500 mt-[10px]">Job Type : </p>
        <p className="text-base font-medium text-gray-800 pl-[5px] mt-[10px]">
          {activeData.job_type || "-"}
        </p>
      </div>
      <div className="flex flex-row mt-[8px]">
        <p className="text-sm text-gray-500">Role : </p>
        <p className="text-base font-medium text-gray-800 pl-[5px]">
          {activeData.job_title || "-"}
        </p>
      </div>
      <div className="flex flex-row">
        <p className="text-sm text-gray-500">Salary Range : </p>
        <p className="text-base font-medium text-gray-800 pl-[5px]">
          {activeData.salary || "-"}
        </p>
      </div>
      <div className="mb-[15px]">
        <p className="text-sm text-gray-500 mb-[5px]">Description : </p>
        <p className="text-base font-medium text-gray-800 pl-[5px]">
            {stripHtmlTags(activeData.jobdesc) || "-"}
        </p>
      </div>
    </div>

    {/* Qualifications */}
    <h2 className="text-lg font-semibold mb-2 mt-7 text-start">Qualifications</h2>
    <p className="text-start font-medium text-gray-800 mb-[5px] pl-[5px]">
      {activeData.qualification || "-"}
    </p>
  </div>
)}





    </div>

   
  </div>
    <Footer/>
    </div>
  );
};



export default AllCards;
