import React, { useEffect, useRef } from "react";
import myImage from "../Assets/images/Image1.png";
import logo from "../Assets/images/Image2.png";
import logo2 from "../Assets/images/Image3.png";
import logo3 from "../Assets/images/Image4.png";
import logo4 from "../Assets/images/Image5.png";

import { Menu } from "antd";
import { useState } from "react";
import SakshamLogo from "../Assets/images/Saksham_Logo.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCaretDown } from "@fortawesome/free-solid-svg-icons";

const Nav = () => {
  // const handleDropdownToggle2 = () => {
  //   const dropdown = document.getElementById("dropdownNavbar2");
  //   dropdown.classList.toggle("hidden");
  // };

  // const handleDropdownClose2 = () => {
  //   const dropdown = document.getElementById("dropdownNavbar2");
  //   setTimeout(() => {
  //     dropdown.classList.add("hidden");
  //   }, 5000);
  // };

  const [visibleSeeker, setVisibleSeeker] = useState(false);
  const [visibleEmployer, setVisibleEmployer] = useState(false);

  const handleSeekerDropdownToggle = () => {
    setVisibleSeeker(!visibleSeeker);
    setVisibleEmployer(false);
  };

  const handleEmployerDropdownToggle = () => {
    setVisibleEmployer(!visibleEmployer);
    setVisibleSeeker(false);
  };
  return (
    <div className="relative bg-[#FFF9F5] flex items-center p-4 pb-[3px] pt-[15px]  w-full md:flex-row ">
      <div className="flex gap-[3px] sm:justify-start below-sm:items-start w-full sm:w-[auto]  items-center">
        <div className="md:w-[74px] md:h-[59.18px] sm:w-[74px] sm:h-[59.18px] w-[51px] h-[42px] below-sm:w-[54.04px] below-sm:h-[50.74px]">
          <img
            src={SakshamLogo}
            alt=""
            className="w-full h-full object-contain "
          />
        </div>
        <div className=" flex gap-2 below-sm:gap-1 items-center flex-col justify-center below-sm:items-start">
          <img
            src={logo}
            alt=""
            className="w-full h-full md:w-[104px] md:h-[27px] sm:w-[64px] sm:h-[24px] w-[46px] h-[18px] below-sm:w-[60.04px] below-sm:h-[25.74px] mt-[8px] object-contain"
          />
          <p className="text-[6px] font-light md:text-[14px] sm:text-[10px] below-sm:text-[8px] text-[#0000004D] ml-[5px] mb-0">
            Nodal Agency
          </p>
        </div>
      </div>

      <div className="flex-grow flex justify-end md:justify-around ">
        <ul className="flex items-center ">
          <li className="text-left font-light md:text-[14px] sm:text-[10px] text-[6px] below-sm:text-[8px] text-[#0000004D] whitespace-nowrap mr-[4px]">
            A Project of
          </li>
          <li className="md:w-[62.39px] md:h-[65.97px] sm:w-[62.39px] sm:h-[65.97px]  w-[34.63px] h-[36.61px] below-sm:w-[40.63px] below-sm:h-[42.61px]">
            <img src={logo2} alt="" className="w-full h-full object-contain" />
          </li>
          <li className="md:w-[119.2px] md:h-[68px] sm:w-[119.2px] sm:h-[68px]  w-[66.04px] h-[37.74px] below-sm:w-[70.04px] below-sm:h-[40.74px]">
            <img src={logo3} alt="" className="w-full h-full object-contain" />
          </li>
        </ul>
      </div>

      <div className="flex-shrink-0 flex items-center space-x-2 relative">
        {" "}
        {/* <button
            onMouseEnter={handleDropdownToggle}
            onClick={handleDropdownToggle}
            className="
            w-[210px] h-[51px]
            p-[16px] pl-[48px] pr-[48px]
            rounded-[6px]
            flex items-center justify-center
            bg-[#192C56] text-white
            text-sm sm:w-[180px] sm:h-[45px] sm:pl-[32px] sm:pr-[32px] lg:w-[210px] lg:h-[51px] lg:pl-[48px] lg:pr-[48px]"
          >
            For Seekers
          </button> */}
        <div className="hidden md:flex space-x-2">
          <div className="relative flex text-center">
            <button
              onMouseEnter={handleSeekerDropdownToggle}
              onClick={handleSeekerDropdownToggle}
              className="w-[150px] h-[38px] p-[16px] rounded-[6px] flex items-center text-center justify-center bg-[#192C56] text-white text-[18px]"
            >
              For Seekers
            </button>

            {visibleSeeker && (
              <div
                id="dropdownNavbarSeeker"
                className="absolute z-30 mt-2 right-0 top-full font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
              >
                <ul className="py-2 text-sm text-start text-gray-700 dark:text-gray-200">
                  <li>
                    <a
                      href="https://jobs.sakshamtifac.org/login"
                      target="_blank"
                      // rel="noopener noreferrer"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Login/Registration
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="https://jobs.sakshamtifac.org/login"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Login
                    </a>
                  </li> */}
                </ul>
              </div>
            )}
          </div>

          <div className="relative flex text-center">
            <button
              onMouseEnter={handleEmployerDropdownToggle}
              onClick={handleEmployerDropdownToggle}
              className="flex items-center justify-center w-full  px-1 text-[#192C56] bg-transparent border border-transparent rounded"
            >
              For Employers
              <svg
                className="w-2.5 h-2.5 ms-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>

            {visibleEmployer && (
              <div
                id="dropdownNavbarEmployer"
                className="absolute z-30 mt-2 right-1 top-full font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-36 dark:bg-gray-700 dark:divide-gray-600"
              >
                <ul className="py-2 text-sm text-start text-gray-700 dark:text-gray-200">
                  <li>
                    <a
                      href="https://jobprovider.sakshamtifac.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Registration
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://company.sakshamtifac.org/login"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Login
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;

{
  /* <a
          href="https://seeker.globalsapio.com/jobs?cpid=&id=srs&source=&jobid=null&orgid=null"
          target="_blank"
        > */
}

{
  /* <button onClick={handleDropdownToggle2} className="relative">
          {" "}
          <FontAwesomeIcon
            icon={faSquareCaretDown}
            className="text-blue ml-2 text-2xl sm:text-xl lg:text-3xl"
          />
        </button> */
}
{
  /* <button
          onMouseEnter={handleDropdownToggle2}
          onMouseLeave={handleDropdownClose2}
          className="flex items-center justify-center w-full py-2 px-3 text-[#192C56]"
        >
          For Employers
          
          <svg
            className="w-2.5 h-2.5 ms-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div
          id="dropdownNavbar2"
          className="absolute hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600 mt-2 right-0 top-full z-30"
        >
          <ul className="py-2 text-sm text-start text-gray-700 dark:text-gray-200">
            <li>
              <a
                href="https://jobprovider.sakshamtifac.org/"
                target="blank"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Registration
              </a>
            </li>
            <li>
              <a
                href="https://www.example.com" 
                rel="noopener noreferrer"
                target="blank"
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Login
              </a>
            </li>
          </ul>
        </div> */
}
//         <div className="relative" ref={dropdownRef}>
//           <button
//             onMouseEnter={handleDropdownToggle}
//             onClick={handleDropdownToggle}
//             className="flex items-center justify-center w-full py-2 px-3 text-[#192C56] bg-transparent border border-transparent rounded"
//           >
//             For Employers
//             <svg
//               className="w-2.5 h-2.5 ms-2.5"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 10 6"
//             >
//               <path
//                 stroke="currentColor"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="m1 1 4 4 4-4"
//               />
//             </svg>
//           </button>

//           {visible && (
//             <div
//               id="dropdownNavbar2"
//               className="absolute z-30 mt-2 right-0 top-full font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
//               onMouseEnter={handleDropdownToggle} // Maintain visibility when hovering over dropdown
//               onClick={handleDropdownToggle}
//             >
//               <ul className="py-2 text-sm text-start text-gray-700 dark:text-gray-200">
//                 <li>
//                   <a
//                     href="https://jobprovider.sakshamtifac.org/"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
//                   >
//                     Registration
//                   </a>
//                 </li>
//                 {/* <li>
//               <a
//                 href="https://www.example.com"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
//               >
//                 Login
//               </a>
//             </li> */}
//               </ul>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Nav;
