import "./App.css";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  HashRouter,
} from "react-router-dom";
import Homepage from "./Pages/Homepage";
import Jobs from "./Pages/Jobs";
import Policy from "./Pages/Policy";
import FieldF from "./Components/FieldForce";
import SS from "./Components/SuccessStories";
import Events from "./Components/Events";
import AboutUs from "./Components/AboutUs";
import JS from "./Components/JobSeeker";
import JP from "./Components/JobProvider";
import Navbar from "./Pages/Navbar";
import AllJobs from "./Components/AllJobs";
import AllCards2 from "./Components/AllCards2";
import BlogsArticles from "./Components/BlogsArticles";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepage />}></Route>
        <Route path="/privacypolicy" element={<Policy />}></Route>

        <Route path="/FieldForce" element={<FieldF />}></Route>
        <Route path="/JobProvider" element={<JP />} />
        <Route path="/SuccessStories" element={<SS />} />
        <Route path="/JobSeeker" element={<JS />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Events" element={<Events />} />

        <Route path="/AllJobs" element={<AllJobs />} />
        <Route path="/AllCards2" element={<AllCards2 />} />
        <Route path="/BlogsArticles" element={<BlogsArticles />} />

        {/* <Route path="/jobs" element={<Jobs />}></Route> */}
      </Routes>
      {/* <Route
        path="https://sakshamtifac.org/privacypolicy"
        element={<Policy />}
      ></Route> */}
    </div>
  );
}

export default App;
